import { useState } from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Header from './layouts/Header';
import Settings from "./pages/billing";
import BuyerListing from "./pages/buyer/listing";
import BuyerListingCreation from "./pages/buyer/listing/creation";
import BuyerListingDetails from "./pages/buyer/listing/detail";
import Favorite from "./pages/buyer/listing/favorite";
import BuyerMyMatching from "./pages/buyer/listing/matching";
import BuyerMyListing from "./pages/buyer/listing/my-listing";
import PreviewBuyer from "./pages/buyer/listing/previewbuyer/preview";
import Dashboard from "./pages/dashboard";
import Enroll from "./pages/enroll";
import Home from "./pages/home";
import NotFound from "./pages/not-found/not-found";
import SellerListing from "./pages/seller/listing";
import SellerListingCreation from "./pages/seller/listing/creation";
import SellerListingDetails from "./pages/seller/listing/detail";
import SellerFavoriteMatchingListing from "./pages/seller/listing/favorite";
import SellerMatchingListing from "./pages/seller/listing/matching";
import MySellerListing from "./pages/seller/listing/my-listing";
import PreviewSeller from "./pages/seller/listing/preview";
import { queryClient } from "./query/query";
import TermAndConditions from "./pages/terms-and-conditions/terms-and-conditions";
import CommunicationPreferences from "./pages/communication-preference";

function App() {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <div>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Header isEditMode={isEditMode} />
          <Routes>
            <Route path="/" element={<Home />} errorElement={<NotFound />} />
            <Route path="/dashboard" element={<Dashboard />} errorElement={<NotFound />} />
            <Route path="/enroll" element={<Enroll />} errorElement={<NotFound />} />
            <Route path="/terms-and-conditions" element={<TermAndConditions />} errorElement={<NotFound />} />
            <Route path="/communication-preferences" element={<CommunicationPreferences />} errorElement={<NotFound />} />

            {/* For seller */}
            <Route path="/seller">
              <Route path="listing">
                <Route path="" element={<SellerListing />} errorElement={<NotFound />} />
                <Route path="creation" element={<SellerListingCreation />} errorElement={<NotFound />} />
                <Route path="details/:id" element={<SellerListingDetails setIsEdit={setIsEditMode} />} errorElement={<NotFound />} />
                <Route path="detail/matching/:id" element={<SellerListingDetails setIsEdit={setIsEditMode} />} errorElement={<NotFound />} />
                <Route path="my-listing" element={<MySellerListing />} errorElement={<NotFound />} />
                <Route path="preview" element={<PreviewSeller />} errorElement={<NotFound />} />
                <Route path="my-matching" element={<SellerMatchingListing />} errorElement={<NotFound />} />
                <Route path="my-favorite" element={<SellerFavoriteMatchingListing />} errorElement={<NotFound />} />
              </Route>
            </Route>

            {/* For buyer */}
            <Route path="/buyer">
              <Route path="listing">
                <Route path="" element={<BuyerListing />} errorElement={<NotFound />} />
                <Route path="creation" element={<BuyerListingCreation />} errorElement={<NotFound />} />
                <Route path="details/:id" element={<BuyerListingDetails setIsEdit={setIsEditMode} />} errorElement={<NotFound />} />
                <Route path="detail/matching/:id" element={<BuyerListingDetails setIsEdit={setIsEditMode} />} errorElement={<NotFound />} />
                <Route path="preview" element={<PreviewBuyer />} errorElement={<NotFound />} />
                <Route path="my-listing" element={<BuyerMyListing />} errorElement={<NotFound />} />
                <Route path="my-matching" element={<BuyerMyMatching />} errorElement={<NotFound />} />
                <Route path="my-favorite" element={< Favorite/>} errorElement={<NotFound />} />
              </Route>
            </Route>

            {/* For settings */}
            <Route path="/settings/:isUpgrade?" element={<Settings />} />

            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </QueryClientProvider>
      </BrowserRouter>
    </div>

  );
}

export default App;
