import ISO3166 from 'iso-3166-1-alpha-2';
import { useEffect, useRef, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Chart } from "react-google-charts";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useMutation, useQueryClient } from 'react-query';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useGetMyBuyerListing, UseAddToFavorite } from '../../../../api/buyer';
import { UseCheckValidSubscription, UseDeleteSellerListing, UseGetMySellerListing, useGetDetailSellerMatching, useGetListingById, useGetNumberViewMessageSeller, useUnpublishListing } from '../../../../api/seller';
import { urlsApi } from '../../../../api/urls/apiUrl';
import CancelCreationModal from '../../../../components/modals/cancel-creation/cancel-creation';
import DeleteSellerListing from '../../../../components/modals/delete-seller-listing/delete-seller-listing';
import UnpublishSellerListing from '../../../../components/modals/publish-seller-listing/unpublish-seller-listing';
import PaymentPopup from '../../../../components/payment/payment-popup';
import FreePublishFailed from '../../../../components/publish/free-publish-failed';
import FreePublishSuccess from '../../../../components/publish/free-publish-success';
import { useKeycloakHooks } from '../../../../hooks/keycloak';
import BuyerSecondMenu from '../../../../layouts/second-headers/buyer';
import SellerSecondMenu from '../../../../layouts/second-headers/seller';
import UseEditSellerCtr from '../../../../provider/use-edit-seller-ctr';
import { removeCacheProduct, removeCacheReceipt } from '../../../../query/removeCache';
import { ReactComponent as Loader } from '../../../../resources/images/Rolling.svg';
import GraphImg from '../../../../resources/images/chart-area.svg';
import Img from '../../../../resources/images/img-preview.svg';
import Image from "../../../../resources/images/light-bulb.svg";
import { scrollToTop } from '../../../../utils/dom';
import { LoadingAllPage } from '../../../../widget/loader';
import NotFound from '../../../not-found/not-found';
import '../../seller.scss';
import CompanyOverviewEdit from '../edit/company-overview-edit';
import FinancialEdit from '../edit/financial-edit';
import GoalEdit from '../edit/goal-edit';
import OpportunityEdit from '../edit/opportunity-edit';
import ValueDriverEdit from '../edit/value-driver-edit';

const SellerListingDetails = ({ setIsEdit }: { setIsEdit: (el: boolean) => void }) => {
  useEffect(() => {
    scrollToTop();
  }, [])

  const _ = require('lodash');

  let { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { keycloak, tokenInfo, initialized } = useKeycloakHooks();
  const [loadingUnpublish, setLoadingUnpublish] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const providerUseEditSeller = { ...UseEditSellerCtr() };

  useEffect(() => {
    if (initialized && !keycloak.authenticated) goToHomePage();
  }, [keycloak.authenticated, initialized]);

  const toggleEditingMode = (value: boolean) => {
    scrollToTop();
    setIsEditMode(value);
    setIsEdit(value);
  };

  const goToHomePage = () => {
    navigate("/");
  };

  const refetchData = () => {
    refetch();
    refetchMySellerListing();
    setShow(false);
  }

  const mutDelete = useMutation({
    mutationFn: UseDeleteSellerListing,
    onSuccess: (data) => {
      if (data.status === 200) {
        refetchData();
        setLoadingDelete(false);
        setShowDeleteModal(!showDeleteModal);
        queryClient.invalidateQueries();
      } else {
        refetchData();
        setLoadingDelete(false);
        setShowDeleteModal(!showDeleteModal);
        queryClient.invalidateQueries();
      }
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries([urlsApi.seller.my_listing, {}]);
      queryClient.invalidateQueries([urlsApi.buyer.mylisting, {}]);
    }
  })

  const mutUnpublish = useMutation({
    mutationFn: useUnpublishListing,
    onSuccess: (data) => {
      refetchData();
      setLoadingUnpublish(false);
      setShowUnpublishModal(!showUnpublishModal);
      removeCacheReceipt(queryClient);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries([urlsApi.seller.my_listing, {}]);
      queryClient.invalidateQueries([urlsApi.buyer.mylisting, {}]);
    }
  })

  const mutPublish = useMutation({
    mutationFn: useUnpublishListing,
    retry: 0,
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries([urlsApi.seller.my_listing, {}]);
      queryClient.invalidateQueries([urlsApi.buyer.mylisting, {}]);
    }
  })


  const [activeId, setActiveId] = useState<any>("null");
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const [showPublishSuccess, setShowPublishSuccess] = useState(false);
  const [showPublishFailed, setShowPublishFailed] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [showPaymentModalForUnlock, setShowPaymentModalForUnlock] = useState<boolean>(false);
  const [isPublishLoading, setIsPublishLoading] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const [isMyDetailListing, setIsMyDetailListing] = useState<boolean | undefined>();

  const [enabledGetMySellerListing, setEnabledGetMySellerListing] = useState(true);
  const { data: mySellerListing, isFetched: isMySellerListingFetched, refetch: refetchMySellerListing } = UseGetMySellerListing(enabledGetMySellerListing);

  const [enabledGetMyBuyerListing, setEnabledGetMyBuyerListing] = useState(true);
  const { data: myBuyerListing, isFetched: isMyBuyerListingFetched } = useGetMyBuyerListing(enabledGetMyBuyerListing);

  const [enabledViewNumberMessage, setEnabledViewNumberMessage] = useState(true);
  const { data: numberMessage, isFetched: isFetchNumberMessage } = useGetNumberViewMessageSeller("seller",enabledViewNumberMessage);

  const { data: dataValidSubscription, refetch: refetchCheck } = UseCheckValidSubscription();

  const [enabledGetListing, setEnabledGetListing] = useState(true);
  const [enabledDetailMatching, setEnabledDetailMatching] = useState(false);
  const { data, refetch, isLoading } = useGetListingById(id, enabledGetListing);

  const { data: detailMatching, isLoading: detailMatchingLoading, isFetched: dataMatchingFetched, refetch: refetchMatching } = useGetDetailSellerMatching(data?.seller?.id_user, enabledDetailMatching);

  const [activeSection, setActiveSection] = useState('Opportunity');

  const opportunityRef = useRef(null);
  const companyOverviewRef = useRef(null);
  const goalRef = useRef(null);
  const valueDriverRef = useRef(null);

  const handleClickMenu = (ref: any, section: string) => {
    const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
    const adjustment = 130;
    window.scrollTo({
      top: elementPosition - adjustment,
      behavior: 'smooth'
    });
    setActiveSection(section);
  };


  useEffect(() => {
    if (data && data.seller && tokenInfo?.user_uuid) {
      setEnabledGetListing(false);
      if (data?.seller?.id_user === tokenInfo?.user_uuid) {
        setIsMyDetailListing(true);
      } else {
        setEnabledDetailMatching(true);
        setIsMyDetailListing(false);
      }
    }
    
    if (isMyDetailListing === false && ((myBuyerListing && (myBuyerListing.is_removed == true || myBuyerListing.is_published == false) || !myBuyerListing))) {
      goToHomePage();
    }
  }, [tokenInfo?.user_uuid, data, id, isMyDetailListing, myBuyerListing, goToHomePage])

  useEffect(() => {
    if (isMySellerListingFetched && mySellerListing && mySellerListing.seller) setEnabledGetMySellerListing(false);
  }, [isMySellerListingFetched])

  useEffect(() => {
    if (isMyBuyerListingFetched) setEnabledGetMyBuyerListing(false);
  }, [isMyBuyerListingFetched])

  useEffect(() => {
    if (isFetchNumberMessage) setEnabledViewNumberMessage(false);
  }, [isFetchNumberMessage])

  useEffect(() => {
    if (detailMatching) {
      setEnabledDetailMatching(false);
    }
  }, [detailMatching]);

  function toggleActive(id: any) {
    if (activeId === id) {
      setActiveId("null");
    } else {
      setActiveId(id);
    }
  }

  const geographicData = data && data?.seller?.geographic_seller ? data?.seller?.geographic_seller?.reduce((acc: any, current: any) => {
    if (!current) {
      return acc;
    }
    const continent = current?.council_region;
    const countryInfo = {
      ISO2: current?.iso2,
      Name: current?.country,
    };

    if (!acc[continent]) {
      acc[continent] = {
        Continent: continent,
        Countries: [countryInfo],
      };
    } else {
      acc[continent].Countries.push(countryInfo);
    }

    return acc;

  }, {}) : {};

  const featuredSolutions = data?.seller?.featured_solution || [];
  const softwareSolutions = data?.seller?.software_solution || [];
  const allSolutions = _.uniq([...softwareSolutions, ...featuredSolutions]);

  const deleteSeller = () => {
    setLoadingDelete(true);
    if (loadingDelete || data?.seller?.is_removed) {
      return;
    }
    mutDelete.mutate(data?.seller?.id);
  }

  const unpublishSeller = () => {
    setLoadingUnpublish(true);
    if (loadingUnpublish || !data?.seller?.is_published) {
      return;
    }
    const dataParam = {
      publish_seller: "false"
    }
    mutUnpublish.mutate({ id: data?.seller?.id, token: keycloak?.token, data: dataParam });
  }

  const publishSellerListing = async (paymentId: string | null, savePaymentMethod?: boolean): Promise<any> => {
    setIsPublishLoading(true);
    const dataParam = {
      publish_seller: "true",
      valid_subscription: dataValidSubscription?.data?.valid_subscription,
      planType: data?.seller?.user_account?.is_premium ? "premium" : "free",
      paymentId,
      savePaymentMethod
    }

    const response = await mutPublish.mutateAsync({ id: data?.seller?.id, data: dataParam });

    return response?.data;
  }


  const goToDelete = () => {
    if (data?.seller?.is_removed) {
      return;
    }
    else {
      setShowDeleteModal(!showDeleteModal);
    }
  }

  const goToUnlock = () => {
    if (detailMatching?.data?.matching?.isUnlock) {
      return;
    }
    else {
      setShowPaymentModalForUnlock(true);
    }
  }

  const goToUnpublishListing = () => {
    if (!data?.seller?.is_published || data?.seller?.is_removed) {
      return;
    } if (mandaDeletedMandaFields.some((field) => hasMandaDeletedMandaValue(data?.seller?.[field])) ||
      hasMandaDeletedMandaValue(allSolutions) ||
      hasMandaDeletedMandaValue(Object.values(geographicData)) ||
      data?.seller?.revenue_generated?.find((el: any) => el?.amount_value === -1) ||
      data?.seller?.profit_mades?.find((el: any) => el?.amount_value === -1)) return;
    else {
      setShowUnpublishModal(!showUnpublishModal);
    }
  }

  const mandaDeletedMandaFields = [
    "stage",
    "opportunity",
    "company_reason",
    "location",
    "founded",
    "company_size",
    "business_model",
    "valuation",
    "targeted_industry",
    "technology_partnership",
    "reason_listing",
    "desired_acquisition",
    "urgency",
    "value_driver",
    "key_assets",
    "targeted_audience",
    "top_competitors",
    "structure_ownership",
    "raised_funds",
    "quarter_revenue",
    "profitability",
  ];

  // useEffect(() => {
  //   console.log("1", isMyDetailListing);
  //   if (isMyDetailListing === false && ((myBuyerListing && (myBuyerListing.is_removed == true || myBuyerListing.is_published == false) || !myBuyerListing))) {
  //     goToHomePage();
  //   }
  // }, []);

  const hasMandaDeletedMandaValue = (value: any): boolean => {
    if (typeof value === "string") {
      return value === "manda_deleted_manda";
    } else if (Array.isArray(value)) {
      return value.some((item: any) => hasMandaDeletedMandaValue(item));
    } else if (typeof value === "object" && value !== null) {
      return Object.values(value).some((item) => hasMandaDeletedMandaValue(item));
    }
    return false;
  };

  const goToPublishListing = async () => {
    if (data?.seller?.is_published || data?.seller?.is_removed) {
      return;
    } else if (
      mandaDeletedMandaFields.some((field) => hasMandaDeletedMandaValue(data?.seller?.[field])) ||
      hasMandaDeletedMandaValue(allSolutions) ||
      hasMandaDeletedMandaValue(Object.values(geographicData)) ||
      data?.seller?.revenue_generated?.find((el: any) => el?.amount_value === -1) ||
      data?.seller?.profit_mades?.find((el: any) => el?.amount_value === -1)
    ) {
      setShowPublishFailed(true);
      refetchCheck();
      refetchData();
      return;
    } else {
      if (dataValidSubscription?.data?.valid_subscription) {
        const result = await publishSellerListing(null);
        if (result?.success === true) {
          queryClient.invalidateQueries();
          setIsPublishLoading(false);
          setShowPublishSuccess(true);
          refetchCheck();
          refetchData();
        } else {
          queryClient.invalidateQueries();
          setIsPublishLoading(false);
          setShowPublishFailed(true);
          refetchCheck();
          refetchData();
        }
      } else {
        setShowPaymentModal(true);
      }
    }
  }

  const doneEditSeller = async () => {
    scrollToTop();
    toggleEditingMode(false);
    setShow(false);
    refetch();
    refetchMySellerListing();
  }

  const mutAddToFavorite = useMutation({
    mutationFn: UseAddToFavorite,
    onSuccess: (data) => {
      if (data.status === 200) {
        refetchMatching();
      } else {
        refetchMatching();
      }
    }
  })

  const toggleFavorite = async (itemId: any): Promise<any> => {
    const dataParam = {
      idBuyer: itemId,
    }
    const response = await mutAddToFavorite.mutateAsync(dataParam);
    return response;
  }

  const revenueDataChart = data && data?.seller?.revenue_generated
    ? data?.seller?.revenue_generated.map((row: any) => {
      return [
        `${row.year}`,
        row.amount_value,
        "#ff6600"
      ];
    }).sort((a: any, b: any) => parseInt(a[0]) - parseInt(b[0]))
    : [];

  const profitDataChart = data && data?.seller?.profit_mades
    ? data?.seller?.profit_mades.map((row: any) => {
      return [
        `${row.year}`,
        row.amount_value,
        "#ff6600"
      ];
    }).sort((a: any, b: any) => parseInt(a[0]) - parseInt(b[0]))
    : [];

  const paymentMethodCallBack = async (
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: 'success' | 'error';
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    setLoading(true);

    try {
      const data = await publishSellerListing(paymentMethodId, savePaymentMethod);

      if (data?.success) {
        setShow(false);
        queryClient.invalidateQueries();
        setShowPaymentStatus && setShowPaymentStatus(true, {
          status: 'success',
          invoiceNumber: data.invoice_number,
          invoiceUrl: data.invoice_url
        })
      }
    } catch (error) {
      setShow(false);
      queryClient.invalidateQueries();
      setShowPaymentStatus && setShowPaymentStatus(true, {
        status: 'error',
      });
    } finally {
      removeCacheProduct(queryClient);
      setLoading(false);
    }
  }



  const handleStatusOk = () => {
    refetchData();
    setShowPaymentModal(false);
    setIsPublishLoading(false);
  }

  if (keycloak?.authenticated && isNaN(Number(id))) {
    return <NotFound />;
  }

  const handleGoToFavorite = () => {
    if (keycloak?.authenticated) { }
    else keycloak?.login();
  }

  const openCancelModal = () => {
    setShowCancelModal(true);
  };

  const closeCancelModal = () => {
    setShowCancelModal(false);
  };

  const leaveCreationListing = () => {
    refetchData();
    closeCancelModal();
  }

  const goToPreview = () => {
    if (data?.seller?.is_removed) return;
    if (mandaDeletedMandaFields.some((field) => hasMandaDeletedMandaValue(data?.seller?.[field])) ||
      hasMandaDeletedMandaValue(allSolutions) ||
      hasMandaDeletedMandaValue(Object.values(geographicData)) ||
      data?.seller?.revenue_generated?.find((el: any) => el?.amount_value === -1) ||
      data?.seller?.profit_mades?.find((el: any) => el?.amount_value === -1)) return;
    // navigate('/seller/listing/preview');
    openNewTab('/seller/listing/preview');
  };

  function formatBusinessModels(businessModels: string[] | undefined): string {
    if (!Array.isArray(businessModels) || businessModels.length === 0) {
      return '';
    }

    return businessModels.map((model, index) => `${model}`).join(' | ');
  }

  const openNewTab = (url: string) => {
    const fullUrl = window.location.origin + url;
    const newTab = window.open(fullUrl, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      navigate(url);
    }
  };

  const goToMessaging = (viewType: boolean) => {
    var messagingUrl = '';
    if(viewType) messagingUrl = `${process.env.REACT_APP_MESSAGING_URL}`;
    else messagingUrl = `${process.env.REACT_APP_MESSAGING_URL}/${data.seller?.id_user}?type=b2s`;
    window.open(messagingUrl, '_blank')
  }

  return (
    <>
      {(isLoading || !initialized || !data || detailMatchingLoading) && <LoadingAllPage />}
      {(!isLoading && data && initialized && (data.seller || dataMatchingFetched)) && (
        <div id="sellerDetails" className={`findIsvcontainer programContainer sellerContainer my-listing ${isEditMode ? "overlay" : ""}`}>
          {
            isMyDetailListing ? (
              <SellerSecondMenu
                showSearch={false}
                handleGoToFavorite={handleGoToFavorite}
              />
            ) : (
              <BuyerSecondMenu
                showSearch={false}
                handleGoToFavorite={handleGoToFavorite}
              />
            )
          }

          <div className='filterFindIsv leftTop hScroll'>
            <div className='contentFilter mt-0'>
              <div className='filter-container'>
                <div className='matchFilter'>
                  <ul className='align-items-center'>
                    <li>
                      <div style={{ marginRight: "6px" }}>
                        <div className="logo">
                          <span className='icon-building1 ico'></span>
                        </div>
                        {
                          data?.seller && isMyDetailListing == false && detailMatching && detailMatching?.data?.matching && myBuyerListing && myBuyerListing?.userAccount?.is_premium === true && (
                            <div className='match m-0'>
                              <span>match</span>
                              <span>{parseInt(detailMatching?.data?.matching?.matching) < 60 ? '<60' : detailMatching?.data?.matching?.matching}%</span>
                            </div>
                          )
                        }
                      </div>
                    </li>
                    <ul className="flex-column">
                      {
                        (!data?.seller?.is_removed && data?.seller?.stage !== "manda_deleted_manda") ?
                          <li className="title d-flex">{data?.seller?.stage}{" "}{"seller"} {
                            data?.seller && isMyDetailListing == false && (
                              <li>
                                <span style={{ marginLeft: "0.5rem", color: "#ccc" }} className={`icon-star-${detailMatching?.data?.matching?.isFavorite ? 'on fs-4' : 'off fs-4'}`} onClick={() => toggleFavorite(detailMatching?.data?.matching?.id)}>
                                </span></li>
                            )
                          }</li>
                          :
                          <>
                            <span className="txt"><Skeleton count={1.5} /></span>
                            <br />
                          </>
                      }
                      {
                        isMyDetailListing && data?.seller?.is_published && (
                          <li><span className="status">Status:</span> <span className="published"><span className='icon-check-article'></span> Published </span></li>
                        )
                      }
                      {
                        isMyDetailListing && !data?.seller?.is_published && (
                          <li className="d-flex text-nowrap"><span className="status">Status:</span> <span className="unpublished"><svg style={{ margin: "0 3px" }} width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="#fa394d" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
                          </svg>  Unpublished (Draft) </span></li>
                        )
                      }
                    </ul>
                  </ul>
                </div>
                {
                  isEditMode && (
                    <>
                      <ul className={`btn msgListing`}>

                        <div
                          className='editDoneIsv containerBtnBtn pl-5 pr-5 mt-3'
                          onClick={() => providerUseEditSeller.isLoading ? null : doneEditSeller()}
                        >
                          <a className="editDoneIsv">Done editing</a>
                        </div>
                      </ul>
                      <br></br>
                    </>
                  )
                }
              </div>

              {
                isMyDetailListing && data?.seller?.is_published === false && (
                  <button
                    className={`btn btn-devinsider ${data?.seller?.is_removed ? ' disabled-ma-btn' : ' containerBtnBtn'} pl-5 pr-5`}
                    onClick={() => {
                      !isPublishLoading && goToPublishListing();
                    }}
                  >
                    {isPublishLoading ? <Loader /> : "Publish"}
                  </button>
                )
              }

              {
                isMyDetailListing && !isEditMode && (
                  <ul className={`btn msgListing`}>
                    <div className='messag mt-3' onClick={() => goToMessaging(true)}>
                      View {(numberMessage  && numberMessage.nbr && numberMessage.nbr > 0) ? numberMessage.nbr : null} {(numberMessage && numberMessage.nbr && numberMessage.nbr === 1) ? "message" : "messages"}
                    </div>
                    <li className='pl-1 pr-1 threeP mt-3'>
                      {
                        isMyDetailListing && (
                          <NavLink className="det" to='#' onClick={() => setShow(!show)}>
                            <span className='icon-inprogress'></span>
                          </NavLink>
                        )
                      }
                      {show && (
                        <ul className='editListing'>
                          <li onClick={() => toggleEditingMode(true)}>Edit listing</li>
                          <li
                            className={`${(data?.seller?.is_removed || mandaDeletedMandaFields.some((field) => hasMandaDeletedMandaValue(data?.seller?.[field])) ||
                              hasMandaDeletedMandaValue(allSolutions) ||
                              hasMandaDeletedMandaValue(Object.values(geographicData)) ||
                              data?.seller?.revenue_generated?.find((el: any) => el?.amount_value === -1) ||
                              data?.seller?.profit_mades?.find((el: any) => el?.amount_value === -1)) ? 'disable-listing-option' : 'enable-listing-option'}`}
                            onClick={() => goToPreview()}
                          >
                            Preview listing
                          </li>
                          <li
                            className={`${data?.seller?.is_removed ? 'disable-listing-option' : 'enable-listing-option'}`}
                            onClick={() => data?.seller?.is_published === true ? goToUnpublishListing() : goToPublishListing()}
                          >
                            {data?.seller?.is_published === true ? "Unpublish listing" : "Publish listing"}
                          </li>
                          <li
                            className={`${data?.seller?.is_removed ? 'disable-listing-option' : 'enable-listing-option'}`}
                            onClick={() => goToDelete()}
                          >
                            Delete listing
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>

                )}

              {
                !isMyDetailListing && !isEditMode && (

                  <button
                    className='btn btn-devinsider px-5 cancel'
                    onClick={() => goToMessaging(false)}
                  >
                    Send a message
                  </button>

                )
              }

              <div className={`block titleLeft border-0 ${activeSection === 'Opportunity' ? 'active' : ''}`} onClick={() => handleClickMenu(opportunityRef, 'Opportunity')}>
                <span className="txtL">1</span> <span className='txt'>Opportunity in short</span>
              </div>
              <div className={`block titleLeft border-0 ${activeSection === 'Company overview' ? 'active' : ''}`} onClick={() => handleClickMenu(companyOverviewRef, 'Company overview')}>
                <span className="txtL">2</span> <span className='txt'>Company overview</span>
              </div>
              <div className={`block titleLeft border-0 ${activeSection === 'Goal' ? 'active' : ''}`} onClick={() => handleClickMenu(goalRef, 'Goal')}>
                <span className="txtL">3</span> <span className='txt'>Goal</span>
              </div>
              <div className={`block titleLeft border-0 ${activeSection === 'Value driver' ? 'active' : ''}`} onClick={() => handleClickMenu(valueDriverRef, 'Value driver')}>
                <span className="txtL">4</span> <span className='txt'>Value drivers</span>
              </div>
            </div>
          </div >
          <div className='container mx-auto minHeightwin sellerRight'>
            <div className='listOfIsv completePage mt-5'>
              <div className='scrollContainer'>
                <div className='contentUser' ref={opportunityRef}>
                  {
                    isEditMode && (
                      <a
                        className="editItem"
                        onClick={() => providerUseEditSeller.setShowOpportunityEdit(true)}
                      >
                        Edit
                        <span className="icon-edit-full"></span>
                      </a>
                    )
                  }
                  <h2 className='title'>Opportunity in short {data?.seller?.is_published && "/ snapshot"}</h2>
                  <span className="txt">
                    {(data?.seller?.is_removed || data?.seller?.opportunity === "manda_deleted_manda") ? <Skeleton count={3.5} /> : data?.seller?.opportunity}
                  </span>
                </div>
                <div className='contentUser' ref={companyOverviewRef}>
                  {
                    isEditMode && (
                      <a
                        className="editItem"
                        onClick={() => providerUseEditSeller.setShowCompanyOverviewEdit(true)}
                      >
                        Edit
                        <span className="icon-edit-full"></span>
                      </a>
                    )
                  }
                  <h2 className='title'>Company overview</h2>
                  <span className="txt">
                    {(data?.seller?.is_removed || data?.seller?.company_reason === "manda_deleted_manda") ? <Skeleton count={3.5} /> : data?.seller?.company_reason}
                  </span>
                  <div className='details'>
                    <ul className='listDetails justify-content-between'>
                      <li className="item">
                        {
                          (!data?.seller?.is_removed && data?.seller?.location !== "manda_deleted_manda") ? (
                            <>
                              <span className="ico icon-location"></span>
                              <div>Location</div>
                              <span className="txt">{ISO3166.getCountry(data?.seller?.location)}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                      <li className="item">
                        {
                          (!data?.seller?.is_removed && data?.seller?.founded !== "manda_deleted_manda") ? (
                            <>
                              <span className="ico icon-founded"></span>
                              <div>Founded</div>
                              <span className="txt">{data?.seller?.founded}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                      <li className="item">
                        {
                          (!data?.seller?.is_removed && data?.seller?.company_size !== "manda_deleted_manda") ? (
                            <>
                              <span className="ico icon-compSize"></span>
                              <div>Company size</div>
                              <span className="txt">{data?.seller?.company_size}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                      <li className="item">
                        {
                          (!data?.seller?.is_removed && data?.seller?.business_model?.find((el: string) => el !== "manda_deleted_manda")) ? (
                            <>
                              <span className="ico">
                                <img
                                  src={Image}
                                  alt=""
                                  className="mx-auto d-block"
                                  width={30}
                                />
                              </span>
                              <div>Business model</div>
                              <span className="txt">{formatBusinessModels(data?.seller?.business_model)}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                      <li className="item">
                        {
                          (!data?.seller?.is_removed && data?.seller?.stage !== "manda_deleted_manda") ? (
                            <>
                              <span className="ico icon-stage"></span>
                              <div>Stage</div>
                              <span className="txt">{data?.seller?.stage}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                      <li className="item">
                        {
                          (!data?.seller?.is_removed && data?.seller?.valuation !== "manda_deleted_manda") ? (
                            <>
                              <span className="ico icon-valuation"></span>
                              <div>Valuation</div>
                              <span className="txt">{data?.seller?.valuation}</span>
                            </>
                          ) : (
                            <img
                              src={Img}
                              width={50}
                              height={60}
                            />
                          )
                        }
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className='title small' key={"solutions"}>Software solution (Horizontal)</h2>
                    {(data?.seller?.is_removed || allSolutions?.find((el: string) => el === "manda_deleted_manda")) ? (
                      <>
                        <span className="txt"><Skeleton count={1.5} /></span>
                      </>
                    ) : (
                      <ul className='listTopic'>
                        {
                          allSolutions?.map((el: string) => (
                            <>
                              <span className="topic">{el}</span>
                            </>
                          ))}
                      </ul>
                    )}
                  </div>
                  <div>
                    <h2 className='title small'>Targeted Industry (Vertical)</h2>
                    {(data?.seller?.is_removed || data?.seller?.targeted_industry?.find((el: string) => el === "manda_deleted_manda")) ? (
                      <>
                        <span className="txt"><Skeleton count={1.5} /></span>
                      </>
                    ) : (
                      <ul className='listTopic'>
                        {
                          data?.seller?.targeted_industry?.map((el: string) => (
                            <>
                              <span className="topic">{el}</span>
                            </>
                          ))}
                      </ul>
                    )}
                  </div>
                  <div>
                    <h2 className='title small'>Geographic sales footprint</h2>
                    {(data?.seller?.is_removed || Object.values(geographicData)?.find((el: any) => el?.Continent === "manda_deleted_manda")) ? (
                      <>
                        <span className="txt"><Skeleton count={1.5} /></span>
                      </>
                    ) : (
                      <div className="geographicList">
                        <Accordion defaultActiveKey={activeId}>
                          {
                            geographicData && Object.values(geographicData)?.map((item: any, index: number) => {
                              return (
                                <div
                                  className={
                                    activeId == index.toString()
                                      ? "panel-wrap active-panel"
                                      : "panel-wrap"
                                  }
                                  key={index}
                                >
                                  <Accordion.Item eventKey={index.toString()}>
                                    <div className="panel-header">
                                      <Accordion.Header
                                        onClick={() => toggleActive(index.toString())}
                                        className="panel-toggle selectedItem"
                                      >
                                        <span className="topic">    {item && item?.Continent}</span>
                                      </Accordion.Header>
                                    </div>

                                    <Accordion.Body>
                                      {
                                        item &&
                                        item?.Countries &&
                                        item?.Countries?.map((pays: any, key: number) => {
                                          return (
                                            <div className="panel-body" key={key}>
                                              <span className="topic">
                                                {pays?.Name}
                                              </span>
                                            </div>
                                          )
                                        })
                                      }
                                    </Accordion.Body>
                                  </Accordion.Item>

                                </div>
                              )
                            })
                          }
                        </Accordion>

                      </div>
                    )}
                  </div>
                  {/* {
                    data?.seller?.have_technology && (
                      <div>
                        <h2 className='title small'>Technology partnerships</h2>
                        {(data?.seller?.is_removed || data?.seller?.technology_partnership?.find((el: string) => el === "manda_deleted_manda")) ? (
                          <>
                            <span className="txt"><Skeleton count={1.5} /></span>
                          </>
                        ) : (
                          <ul className='listTopic'>
                            {
                              data?.seller?.technology_partnership?.map((el: string) => (
                                <>
                                  <span className="topic">{el}</span>
                                </>
                              ))
                            }
                          </ul>
                        )}
                      </div>
                    )
                  } */}
                </div>
                <div className='contentUser' ref={goalRef}>
                  {
                    isEditMode && (
                      <a
                        className="editItem"
                        onClick={() => providerUseEditSeller.setShowGoalEdit(true)}
                      >
                        Edit
                        <span className="icon-edit-full"></span>
                      </a>
                    )
                  }
                  <h2 className='title'>Goal</h2>
                  <div>
                    <h2 className='title small'>Reason for listing</h2>
                    {(data?.seller?.is_removed || data?.seller?.reason_listing === "manda_deleted_manda") ? (
                      <>
                        <span className="txt mt-2"><Skeleton count={2.5} /></span>
                      </>
                    ) : (
                      <ul className='listTopic'>
                        <span className="topic">{data?.seller?.reason_listing}</span>
                      </ul>
                    )}
                    {!data?.seller?.is_removed && data?.seller?.reason_listing === "I am looking for an acquirer" && (
                      <span className='txt mt-2 d-inline-block'>
                        {data?.seller?.desc_reason_listing}
                      </span>
                    )}
                    <div>
                      <h2 className='title small'>Desired acquisition type</h2>
                      {(data?.seller?.is_removed || data?.seller?.desired_acquisition === "manda_deleted_manda") ? (
                        <>
                          <span className="txt"><Skeleton count={1.5} /></span>
                        </>
                      ) : (
                        <ul className='listTopic'>
                          <span className="topic">{data?.seller?.desired_acquisition}</span>
                        </ul>
                      )}
                    </div>
                    <div>
                      <h2 className='title small'>Urgency</h2>
                      {(data?.seller?.is_removed || data?.seller?.urgency === "manda_deleted_manda") ? (
                        <>
                          <span className="txt"><Skeleton count={1.5} /></span>
                        </>
                      ) : (
                        <ul className='listTopic'>
                          <span className="topic">{data?.seller?.urgency}</span>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className='contentUser' ref={valueDriverRef}>
                  {
                    isEditMode && (
                      <a
                        className="editItem"
                        onClick={() => providerUseEditSeller.setShowValueDriverEdit(true)}
                      >
                        Edit
                        <span className="icon-edit-full"></span>
                      </a>
                    )
                  }
                  <h2 className='title'>Value drivers</h2>
                  <div>
                    <h2 className='title small'>Top value driver</h2>
                    {(data?.seller?.is_removed || data?.seller?.value_driver === "manda_deleted_manda") ? (
                      <>
                        <span className="txt"><Skeleton count={1.5} /></span>
                      </>
                    ) : (
                      <ul className='listTopic'>
                        <span className="topic">{data?.seller?.value_driver}</span>
                      </ul>
                    )}
                    <div>
                      <h2 className='title small'>Key assets</h2>
                      <span className="txt">{(data?.seller?.is_removed || data?.seller?.key_assets === "manda_deleted_manda") ? <Skeleton count={3.5} /> : data?.seller?.key_assets}</span>
                    </div>
                    <div>
                      <h2 className='title small'>Target audience description</h2>
                      <span className="txt">{(data?.seller?.is_removed || data?.seller?.targeted_audience === "manda_deleted_manda") ? <Skeleton count={3.5} /> : data?.seller?.targeted_audience}</span>
                    </div>
                    {
                      data?.seller?.top_competitors && (
                        <div>
                          <h2 className='title small'>Top competitor</h2>
                          <span className="txt">{(data?.seller?.is_removed || data?.seller?.top_competitors === "manda_deleted_manda") ? <Skeleton count={3.5} /> : data?.seller?.top_competitors}</span>
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className='contentUser'>
                  {
                    isEditMode && (
                      <a
                        className="editItem"
                        onClick={() => providerUseEditSeller.setShowFinancialEdit(true)}
                      >
                        Edit
                        <span className="icon-edit-full"></span>
                      </a>
                    )
                  }
                  <h2 className='title'>Financial</h2>
                  <div>
                    <h2 className='title small'>Ownership structure</h2>
                    <span className="txt">{(data?.seller?.is_removed || data?.seller?.structure_ownership === "manda_deleted_manda") ? <Skeleton count={3.5} /> : data?.seller?.structure_ownership}</span>
                    <div className='details'>
                      <ul className='listDetails justify-content-between'>
                        <li className="item">
                          {
                            (!data?.seller?.is_removed && data?.seller?.raised_funds !== "manda_deleted_manda") ? (
                              <>
                                <span className="ico icon-profitability"></span>
                                <div>Raised funds</div>
                                <span className="txt">{data?.seller?.raised_funds}</span>
                              </>
                            ) : (
                              <img
                                src={Img}
                                width={50}
                                height={60}
                              />
                            )
                          }
                        </li>
                        <li className="item">
                          {
                            (!data?.seller?.is_removed && data?.seller?.quarter_revenue !== "manda_deleted_manda") ? (
                              <>
                                <span className="ico icon-sellers"></span>
                                <div>Last quarter revenue</div>
                                <span className="txt">{data?.seller?.quarter_revenue}</span>
                              </>
                            ) : (
                              <img
                                src={Img}
                                width={50}
                                height={60}
                              />
                            )
                          }
                        </li>
                        <li className="item">
                          {
                            (!data?.seller?.is_removed && data?.seller?.profitability !== "manda_deleted_manda") ? (
                              <>
                                <span className="ico icon-compSize"></span>
                                <div>Profitability</div>
                                <span className="txt">{data?.seller?.profitability}</span>
                              </>
                            ) : (
                              <img
                                src={Img}
                                width={50}
                                height={60}
                              />
                            )
                          }
                        </li>
                      </ul>
                    </div>
                    {
                      data?.seller?.revenue_generated?.some((el: any) => el?.amount_value !== 0) && (
                        <div>
                          <h2 className='title small'>Total revenue generate over the past 3 years</h2>
                          {
                            revenueDataChart?.length > 0 && !data?.seller?.is_removed && data?.seller?.revenue_generated?.find((el: any) => el?.amount_value !== -1) && (
                              <Chart chartType="ColumnChart" data={[["Year", "Amount", { role: "style" }], ...revenueDataChart]} options={{ legend: 'none', hAxis: { title: 'Year', titleTextStyle: { italic: false, bold: true } }, vAxis: { title: 'Amount ($)', titleTextStyle: { italic: false, bold: true } } }} />
                            )
                          }
                          {
                            (data?.seller?.is_removed || data?.seller?.revenue_generated?.find((el: any) => el?.amount_value === -1)) && (
                              <>
                                <ul className='listDetails'>
                                  <li className="item">
                                    <img
                                      src={GraphImg}
                                      width={50}
                                      height={200}
                                    />
                                  </li>
                                </ul>
                              </>
                            )
                          }
                        </div>
                      )
                    }

                    {
                      data?.seller?.profit_mades?.some((el: any) => el?.amount_value !== 0) && (
                        <div>
                          <h2 className='title small'>Total profit made in the past 3 years</h2>
                          {
                            profitDataChart?.length > 0 && !data?.seller?.is_removed && data?.seller?.profit_mades?.find((el: any) => el?.amount_value !== -1) && (
                              <Chart chartType="ColumnChart" data={[["Year", "Amount", { role: "style" }], ...profitDataChart]} options={{ legend: 'none', hAxis: { title: 'Year', titleTextStyle: { italic: false, bold: true } }, vAxis: { title: 'Amount ($)', titleTextStyle: { italic: false, bold: true } } }} />
                            )
                          }
                          {
                            (data?.seller?.is_removed || data?.seller?.profit_mades?.find((el: any) => el?.amount_value === -1)) && (
                              <>
                                <ul className="listDetails">
                                  <li className="item">
                                    <img
                                      src={GraphImg}
                                      width={50}
                                      height={200}
                                    />
                                  </li>
                                </ul>
                              </>
                            )
                          }
                        </div>
                      )
                    }


                  </div>
                  <div className='pagginationCategory'>
                    <div className='pagination'>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DeleteSellerListing
              isOpen={showDeleteModal}
              title='Delete listing'
              closeModal={() => setShowDeleteModal(!showDeleteModal)}
              confirmDelete={deleteSeller}
              loading={loadingDelete}
            />

            <UnpublishSellerListing
              isOpen={showUnpublishModal}
              title='Unpublish listing'
              closeModal={() => setShowUnpublishModal(!showUnpublishModal)}
              confirmUnpublish={unpublishSeller}
              loading={loadingUnpublish}
            />

            <FreePublishSuccess
              show={showPublishSuccess}
              handleOk={() => {
                refetch();
                setShow(false);
                setShowPublishSuccess(!showPublishSuccess)
              }}
            />

            <FreePublishFailed
              show={showPublishFailed}
              handleOk={() => {
                refetch();
                setShow(false);
                setShowPublishFailed(!showPublishFailed)
              }}
            />

            <OpportunityEdit
              show={providerUseEditSeller.showOpportunityEdit}
              data={providerUseEditSeller.state.necessary_informations}
              value={data?.seller}
              idSeller={data?.seller?.id}
              setData={providerUseEditSeller.setNecessaryInformations}
              handleOk={() => providerUseEditSeller.setShowOpportunityEdit(false)}
              {...providerUseEditSeller}
            />

            <CompanyOverviewEdit
              show={providerUseEditSeller.showCompanyOverviewEdit}
              handleOk={() => providerUseEditSeller.setShowCompanyOverviewEdit(false)}
              value={data?.seller}
              idSeller={data?.seller?.id}
              dataNecessaryInformation={providerUseEditSeller.state.necessary_informations}
              setDataNecessaryInformation={providerUseEditSeller.setNecessaryInformations}
              dataCompanyBasic={providerUseEditSeller.state.company_basics}
              setDataCompanyBasic={providerUseEditSeller.setCompanyBasics}
              dataAcquisition={providerUseEditSeller.state.acquisition_overview}
              setDataAcquisition={providerUseEditSeller.setAcquisitionOverview}
              {...providerUseEditSeller}
            />
            <GoalEdit
              show={providerUseEditSeller.showGoalEdit}
              handleOk={() => providerUseEditSeller.setShowGoalEdit(false)}
              value={data?.seller}
              idSeller={data?.seller?.id}
              data={providerUseEditSeller.state.acquisition_overview}
              setData={providerUseEditSeller.setAcquisitionOverview}
              {...providerUseEditSeller}
            />
            <ValueDriverEdit
              show={providerUseEditSeller.showValueDriverEdit}
              handleOk={() => providerUseEditSeller.setShowValueDriverEdit(false)}
              data={providerUseEditSeller.state.necessary_informations}
              setData={providerUseEditSeller.setNecessaryInformations}
              value={data?.seller}
              idSeller={data?.seller?.id}
              {...providerUseEditSeller}
            />

            <FinancialEdit
              show={providerUseEditSeller.showFinancialEdit}
              handleOk={() => providerUseEditSeller.setShowFinancialEdit(false)}
              data={providerUseEditSeller.state.financial_details}
              setData={providerUseEditSeller.setFinancialDetails}
              value={data?.seller}
              idSeller={data?.seller?.id}
              {...providerUseEditSeller}
            />

            <CancelCreationModal
              isOpen={showCancelModal}
              closeModal={closeCancelModal}
              confirmCancel={leaveCreationListing}
              title={'Publish listing'}
            />
            <PaymentPopup
              planType={"premium"}
              listingType="seller"
              successCallback={paymentMethodCallBack}
              handleStatusOk={handleStatusOk}
              setShowPaymentModal={setShowPaymentModal}
              title={"Publish listing"}
              showTryAgain={false}
              showContinue={true}
              handleClose={() => setShowPaymentModal(false)}
              handleCancel={() => setShowPaymentModal(false)}
              show={showPaymentModal}
              modalSize="large"
              isUpgradePlan={false}
            />
          </div>
        </div >
      )}

    </>

  );

};

export default SellerListingDetails;
