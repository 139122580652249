import { useEffect, useState } from "react";
import { useKeycloakHooks } from "../../hooks/keycloak";
import { UseChangeCommunityPreferences, UseGetCommunityPreferences, useGetTypeUser } from "../../api/user";
import '../seller/seller.scss';
import { useMutation, useQueryClient } from "react-query";
import { urlsApi } from "../../api/urls/apiUrl";
import { ReactComponent as Loader } from '../../resources/images/Rolling.svg';
import { useNavigate } from "react-router-dom";
import { LoadingAllPage } from '../../widget/loader';
import { Modal } from "react-bootstrap";

export default function CommunicationPreferences() {

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [privateMessage, setPrivateMessage] = useState(true);
    const [updateListing, setUpdateListing] = useState(true);
    const [modalSuccess, setModalSuccess] = useState(false);

    const [enabled, setEnabled] = useState(false);
    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();
    const { data: userRoles } = useGetTypeUser(tokenInfo, enabled);

    const [loading, setLoading] = useState(false);
    const [dataEnable, setDataEnable] = useState(false);
    const { data: commData, isFetched: isFetchCommData, refetch, isLoading } = UseGetCommunityPreferences(tokenInfo?.user_uuid,dataEnable);


    const goToHomePage = () => {
        navigate("/");
    };

    const closeModal = () => {
        setModalSuccess(false);
    }

    useEffect(() => {
        if (initialized && !keycloak.authenticated) goToHomePage();
    }, [keycloak.authenticated, initialized]);

    const mutChangeCommunication = useMutation({
        mutationFn: UseChangeCommunityPreferences,
        onSuccess: (data) => {
            refetch();
            setLoading(false);
            setModalSuccess(true);
        },
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries([urlsApi.mailing.community_preferences+"/"+tokenInfo?.user_uuid, {}]);
            queryClient.invalidateQueries([urlsApi.mailing.community_preferences+"/"+tokenInfo?.user_uuid, {}]);
        }
    })

    useEffect(() => {
        if (keycloak?.token) {
            setEnabled(true);
            setDataEnable(true);
        } 
        if (userRoles) setEnabled(false);
    }, [keycloak?.token, userRoles])

    useEffect(() => {
        if (isFetchCommData) {
            setPrivateMessage(commData?.data?.community_preferences?.privateMessage ?? true);
            setUpdateListing(commData?.data?.community_preferences?.updateListing ?? true);
            setDataEnable(false);
        } 
    }, [commData?.private_message, commData?.update_listing, isFetchCommData])


    const updateCommunicationPreferences = async () => {
        const data = {
            user_id: tokenInfo?.user_uuid,
            private_message: privateMessage,
            update_listing: updateListing
        }
        if(loading) {
            return;
        }

        setLoading(true);
        mutChangeCommunication.mutate(data);
    }

    return (
        <>
            {(isLoading || !initialized || !commData) && <LoadingAllPage />}
            {!isLoading && commData && initialized && 
            <div id="sellerDetails" className="findIsvcontainer programContainer sellerContainer my-listing">
                <div className='container mx-auto minHeightwin sellerRight'>
                    <div className='listOfIsv completePage mt-5'>
                        <div className='scrollContainer'>
                            <h2 className='title'>COMMUNICATION PREFERENCES</h2>
                            <p>{tokenInfo?.email}</p> 
                            <p>If this is not your email address, please close this page as<br/>
                            the email associated with this page was most likely<br/>
                            forwarded to you.</p>
                            <p>Uncheck the types of email notifications you do not want to receive:</p>
                            <div>
                                <input type="checkbox" id="private-message" checked={privateMessage} onChange={() => setPrivateMessage(!privateMessage)}/>
                                <label form="private-message">Private messages sent by M&A Devinsider members</label>
                            </div>
                            <div>
                                <input type="checkbox" id="update-listing" checked={updateListing} onChange={() => setUpdateListing(!updateListing)}/>
                                <label form="update-listing">Updates on buyer and/or seller listings</label>
                            </div>

                            <br/>

                            <button
                                className="btn btn-devinsider containerBtnBtn pl-5 pr-5"
                                onClick={() => updateCommunicationPreferences()}
                            >
                               {loading ? <Loader/> : "Update communication preferences"} 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            <Modal show={modalSuccess} className='createCompanypopup medium forStep5custom'>
                <Modal.Header>
                    <div className='headerpopupCompany noStep'>
                    <h2 className='title'>Communication preferences</h2>
                    <a
                        className='close'
                        onClick={() => closeModal()}
                    >
                        <span className='icon-close-pop'></span>
                    </a>
                    </div>
                </Modal.Header>
                <Modal.Body className=''>
                    <div className='contentBorder forBillingCenter pt-3 pb-0 text-center'>
                    <div className='payement_success'>
                        <div className='ico'>
                            <span className='icon-check-article green'></span>
                        </div>
                        <span className='txt mb-2 title'>Success</span>
                        <p>Your communication preferences has been updated.</p>
                    </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}