export const urlsApi = {
  seller: {
    // baseApi: "/seller/",
    add_seller: "/mam/seller/add",
    listing: "/seller/getAll",
    getAllList: "/public/seller/get-all-listing",
    filters: "/public/seller/filters",
    listDi: "/api/events/get_all_events",
    filter: "/api/events/get_filter_event",
    getPpp: "/payment/get-product-prices/INVESTOR_POST_EVENT",
    solutionFilter: "/api/horizontal-mains?pagination[page]=1&pagination[pageSize]=300&sort[1]=Label:asc",
    industryFilter: "/api/verticals?sort[1]=Label:asc&pagination[start]=0&pagination[limit]=30",
    countryFilter: "/api/countries?pagination[page]=1&pagination[pageSize]=300&sort[1]=Continent:asc&sort[2]=Name:asc",
    enrollSeller: "/seller/enrollSeller",
    addSubscriptionPlan: "/seller/addSubscriptionPlan",
    acquisitionTypes: "/api/what-acquisition-types",
    timeframes: "/api/what-timeframe-lookings",
    how_value_business: "/api/how-value-your-businesses",
    reason_listing_business: "/api/why-business-in-mams",
    lifecycle: "/api/what-stage-lifecycles",
    main_value_business: "/api/where-main-value-of-businesses?sort[1]=Label:asc",
    how_many_people: "/api/how-many-people-employeds",
    business_model: "/api/what-business-models",
    funding_raised: "/api/how-much-funding-raiseds",
    revenue_last_quarter: "/api/how-much-revenue-last-quarters",
    technology_partnership: "/api/what-techno-ecosystem-you-parts?pagination[page]=1&pagination[pageSize]=300",
    profitability: "/api/how-much-profit-last-quarters",
    listing_user: "/mam/seller/listing/user",
    listing_by_id: "/mam/seller/listing",
    delete_seller_listing: "/mam/seller/delete",
    unpublish_seller_listing: "/mam/seller/publish",
    update_seller: "/mam/seller/update",
    my_listing: "/mam/seller/mylisting",
    get_subscription: "/mam/seller/subscription",
    check_valid_subscription: "/mam/seller/subscription/check_validity",
    cancel_renew_subscription: "/mam/seller/subscription/renewal/cancel",
    enable_renew_subscription: "/mam/seller/subscription/renewal/enable",
    upgrade_subscription: "/mam/seller/subscription/update",
    seller_matching:"/mam/seller/match",
    detail_seller_matching: "/matchmaking/buyer",
    unlock: "/mam/seller/unlock"
  },
  buyer: {
    add_buyer: "/mam/buyer/create",
    getAllList: "/public/buyer/get-all-listing",
    getAll: "/mam/buyer/listing",
    filters: "/public/buyer/filters",
    filtersMatching: "/mam/buyer/filter",
    listing_by_id: "/mam/buyer/getOne",
    myspecifictechnology: "/mam/buyer/user/specifictechnology",
    mylisting: "/mam/buyer/mylisting",
    delete: "/mam/buyer/delete",
    unpublish: "/mam/buyer/publish",
    update: "/mam/buyer/update",
    publish_payment: "/mam/buyer/publishPayment",
    get_subscription: "/mam/buyer/subscription",
    get_check: "/mam/buyer/subscription/check_validity",
    cancel_renew_subscription: "/mam/buyer/subscription/renewal/cancel",
    enable_renew_subscription: "/mam/buyer/subscription/renewal/enable",
    upgrade_subscription: "/mam/buyer/subscription/update",
    buyer_matching:"/mam/buyer/match",
    detail_buyer_matching: "/matchmaking/seller",
    unlock: "/mam/buyer/unlock",
    favorite: "/mam/buyer/favorite"
  },
  user: {
    checkTypeUser: "/mam/user/role"
  },
  payment: {
    get_product_prices: '/payment/get-product-prices',
    get_product_price_with_tax: '/payment/get-product-price-with-tax-by-slug-country-vat',
    payment_method: '/payment/payment-method',
    get_currency_by_country: '/payment/get-currency-by-country/',
    get_user_invoices: "/payment/get-user-invoices",
  },
  billing_center: {
    send_contact_support: '/mam/billing_center/contact_support'
  },
  upload: {
    upload_image: '/mam/buyer/company_image/upload',
    get_upload_image: '/mam-upload/buyer_company'
  },
  messaging: {
    view_number_message: "conversation/get-unread-conversation-by-user-type"
  },
  mailing: {
    community_preferences: "/mam/community_preferences"
  }
};
