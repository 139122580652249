import { ChangeEvent, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { generateYearList } from '../../../../../api/seller';
import { useGetStaticParams } from '../../../../../api/staticParams';
import BuyerStep from "../../../../../components/buyer/buyer-step";
import ModalIndustryList from '../../../../../components/modalIndustryList';
import ModalSolutionList from '../../../../../components/modalSolutionList';
import { ICompanyBasicsBuyer } from '../../../../../types/company-basics-buyer';
import { Countries } from '../../../../../widget/countries';
import { renderOptions } from '../../../utils/renderOptions';
import {industriesTextToDisplay, solutionsTextToDisplay} from "../../../../../utils/display_text";

type IProps = {
    handleNextStep: (step: number, isGoingNextStep?: boolean) => void;
    showCancelModal: () => void;
    setData: (data: ICompanyBasicsBuyer) => void;
    data: ICompanyBasicsBuyer;
    validateSteps: Array<number>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
    removeStepInValidated: (step: number) => void;
    addStepToValidated: (step: number) => void;
};
type SelectedOptions = {
    companyHeadCarter: string[];
    employe: string;
};
export default function CompanyBasicsBuyer(props: IProps) {
    const _ = require('lodash');

    const [formState, setFormState] = useState(props?.data);
    let saveBrief: string | undefined;
    let saveCompanyname: string | undefined;
    let saveCompanywebsite: string | undefined;
    let saveFounded: string | undefined;


    if ('brief' in formState && typeof formState['brief'] === 'string') {
        saveBrief = formState['brief'];
    }
    if ('companyname' in formState && typeof formState['companyname'] === 'string') {
        saveCompanyname = formState['companyname'];
    }
    if ('companywebsite' in formState && typeof formState['companywebsite'] === 'string') {
        saveCompanywebsite = formState['companywebsite'];
    }
    if ('founded' in formState && typeof formState['founded'] === 'string') {
        saveFounded = formState['founded'];
    }

    const years = generateYearList();
    const [selectedFounded, setSelectedFounded] = useState<string | undefined>(formState?.founded);

    const [showPIndustry, setShowPIndustry] = useState(false);
    const [showPSolution, setShowPSolution] = useState(false);
    const [selectedIndustry, setSelectedIndustry] = useState<string[]>(formState?.targeted_industry);
    const [selectedSolution, setSelectedSolution] = useState<string[]>(formState?.software_solution);
    const [selectedFeaturedSolution, setSelectedFeaturedSolution] = useState<string[]>(formState?.featured_solution);

    const [selectedGeographicData, setSelectedGeographicData] = useState<any>({});

    const [enableGetIsvBased, setEnableGetIsvBased] = useState(true);

    const { data: companyHeadCarterData, isFetched: isIsvBasedFetched } = useGetStaticParams('COUNTRIES_SORT_NAME', enableGetIsvBased);

    const [enableGetEmployees, setEnableGetEmployees] = useState(true);
    const { data: employeesData, isFetched: isEmployeesFetched } = useGetStaticParams('EMPLOYEES', enableGetEmployees);

    const [enableGetHorizontals, setEnableGetHorizontals] = useState(true);
    const { data: horizontalsData, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enableGetHorizontals);

    const [enableGetCountries, setEnableGetCountries] = useState(true);
    const { data: countriesData, isFetched: isCountriesFetched } = useGetStaticParams('COUNTRIES', enableGetCountries);


    useEffect(() => {
        if (isIsvBasedFetched) setEnableGetIsvBased(false);
    }, [isIsvBasedFetched]);


    useEffect(() => {
        if (isEmployeesFetched) setEnableGetEmployees(false);
    }, [isEmployeesFetched]);

    useEffect(() => {
        if (isHorizontalsFetched) setEnableGetHorizontals(false);
    }, [isHorizontalsFetched]);

    useEffect(() => {
        if (isCountriesFetched) setEnableGetCountries(false);
    }, [isCountriesFetched]);

    useEffect(() => {
        if (isIsvBasedFetched) setEnableGetIsvBased(false);
    }, [isIsvBasedFetched]);

    const [selectedOption, setSelectedOption] = useState({
        companyHeadCarter: '',
        employe: '',
    });

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>, property: string) => {
        const value = event.target.value;
        setSelectedOption((prevState) => ({ ...prevState, [property]: value }));
        props?.setData({ ...formState, [property]: value });
    };

    const [Brief, setBrief] = useState(saveBrief || '');
    const [CompanyName, setCompanyName] = useState(saveCompanyname || '');
    const [CompanyWebSite, setCompanyWebSite] = useState(saveCompanywebsite || '');
    const [CompanyWebSiteError, setCompanyWebSiteError] = useState('');

    const [activeId, setActiveId] = useState<any>("-1");

    function toggleActive(id: any) {
        if (activeId === id) {
            setActiveId("null");
        } else {
            setActiveId(id);
        }
    }

    const geographicData = countriesData && countriesData
        ? countriesData.data.reduce((acc: any, current: any) => {
            if (current && current.attributes) {
                const continent = current.attributes.Continent;
                const iso2 = current.attributes.ISO2;
                const name = current.attributes.Name;

                if (continent && iso2 && name) {
                    if (!acc[continent]) {
                        acc[continent] = {
                            Continent: continent,
                            Countries: [{ ISO2: iso2, Name: name }],
                        };
                    } else {
                        acc[continent].Countries.push({ ISO2: iso2, Name: name });
                    }
                }
            }
            return acc;
        }, {})
        : {};

    const txtBtnCat = solutionsTextToDisplay(formState?.software_solution, formState?.featured_solution);

    const txtBtnIndustry = industriesTextToDisplay(formState?.targeted_industry);

    const addTargetedIndustry = (element: any) => {
        setSelectedIndustry([...selectedIndustry, element?.attributes?.Label]);
    }

    const removeTargetedIndustry = (element: any) => {
        setSelectedIndustry(selectedIndustry?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));
    }

    const addSoftwareSolution = (element: any) => {
        setSelectedSolution([...selectedSolution, element?.attributes?.Label]);
    }

    const addFeaturedSolution = (element: any) => {
        setSelectedFeaturedSolution([...selectedFeaturedSolution, element?.attributes?.Label]);
    }

    const removeSolution = (element: any) => {
        setSelectedSolution(selectedSolution?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));

        setSelectedFeaturedSolution(selectedFeaturedSolution?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));
    }

    const removeItemSelected = (element: any) => {
        setSelectedSolution(selectedSolution?.filter(
            (el: any) => el !== element
        ));

        setSelectedFeaturedSolution(selectedFeaturedSolution?.filter(
            (el: any) => el !== element
        ));
    }

    const [briefError, setBriefError] = useState('');
    const [companyHeadCarterError, setCompanyHeadCarterError] = useState('');
    const [employeError, setEmployeError] = useState('');
    const [yearError, setYearError] = useState('');
    const [geographicSalesError, setGeographicSalesError] = useState('');
    const [industryError, setIndustryError] = useState('');
    const [solutionError, setSolutionError] = useState('');


    const validTargetedIndustry = () => {
        updateState('targeted_industry', selectedIndustry);
        props.setData({ ...formState, targeted_industry: selectedIndustry });
        setShowPIndustry(false);
    }

    const validSoftwareSolution = () => {
        if ([...selectedSolution, ...selectedFeaturedSolution].length === 0) {
            setSolutionError(' ERROR This field is required');
        } else {
            updateState('software_solution', selectedSolution);
            updateState('featured_solution', selectedFeaturedSolution);
            setShowPSolution(false);
        }
        props.setData({ ...formState, software_solution: selectedSolution, featured_solution: selectedFeaturedSolution });
        setShowPSolution(false);
    }

    const backTarget = () => {
        setSelectedIndustry(formState?.targeted_industry);
        setShowPIndustry(false);
    }

    const backSolution = () => {
        setSelectedSolution(formState?.software_solution);
        setSelectedFeaturedSolution(formState?.featured_solution);
        setShowPSolution(false);
    }

    const updateState = (property: string, value: any) => {
        setFormState((prevState: ICompanyBasicsBuyer) => {
            const updatedState = {
                ...prevState,
                [property]: value,
            };
            props?.setData(updatedState);

            return updatedState;
        });
    };

    const handleFoundedChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = evt.target.value;
        setSelectedFounded(value);
        updateState('founded', value);
    };

    const validateForm = () => {
        let hasError = false;

        if (!Brief) {
            setBriefError(' ERROR This field is required');
            hasError = true;
        } else {
            setBriefError('');
        }
        if (CompanyWebSite) {
            const urlPattern = /^(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,6})+$/;

            if (!urlPattern.test(CompanyWebSite)) {
                setCompanyWebSiteError(' ERROR Please enter a valid website format (e.g., www.example.com)');
                hasError = true;
            } else {
                setCompanyWebSiteError('');
            }
        }


        // if (selectedCompanyHeadquarters.length === 0) {
        //     setCompanyHeadCarterError(' ERROR This field is required');
        //     hasError = true;
        // }
        if (!selectedOption.companyHeadCarter) {
            setCompanyHeadCarterError(' ERROR This field is required');
            hasError = true;
        } else {
            setEmployeError('');
        }


        if (!selectedOption.employe) {
            setEmployeError(' ERROR This field is required');
            hasError = true;
        } else {
            setEmployeError('');
        }


        if (!selectedFounded) {
            setYearError(' ERROR This field is required');
            hasError = true;
        } else {
            setYearError('');
        }


        if (Object.keys(selectedGeographicData).length === 0) {
            setGeographicSalesError(' ERROR This field is required');
            hasError = true;
        } else {
            setGeographicSalesError('');
        }
        if (selectedIndustry.length === 0) {
            setIndustryError(' ERROR This field is required');
            hasError = true;
        }

        if ((!selectedSolution && selectedFeaturedSolution) || [...selectedSolution, ...selectedFeaturedSolution].length < 1) {
            setSolutionError(' ERROR This field is required');
            hasError = true;
        }


        return hasError;
    }

    useEffect(() => {
        if (formState?.geographic_sales_footprint) {
            setSelectedGeographicData(formState.geographic_sales_footprint);
        }
    }, [formState]);

    const goToNextStep = (step: number, isGoingNextStep: boolean) => {
        const stepHasErrors = validateForm();

        if (stepHasErrors) {
            if (isGoingNextStep) props?.removeStepInValidated(props?.currentStep);
            return;
        }

        props?.addStepToValidated(props?.currentStep);
        props?.setData(formState);
        props?.handleNextStep(step, isGoingNextStep);
    }

    useEffect(() => {
        const selectedFields = ['brief', 'companyname', 'companywebsite', 'companyHeadCarter', 'employe', 'founded', 'geographic_sales_footprint'];

        selectedFields.forEach((field) => {
            const value = formState[field as keyof ICompanyBasicsBuyer];

            switch (field) {

                case 'companyHeadCarter':
                    setSelectedOption((prevState) => ({ ...prevState, companyHeadCarter: value as string }));
                    break;
                case 'employe':
                    setSelectedOption((prevState) => ({ ...prevState, employe: value as string }));
                    break;
                case 'founded':
                    setSelectedOption((prevState) => ({ ...prevState, founded: value as string }));
                    break;
                case 'geographic_sales_footprint':
                    setSelectedOption((prevState) => ({ ...prevState, geographic_sales_footprint: value }));
                    break;
                default:
                    break;
            }
        });

    }, [formState]);

    useEffect(() => {
        if (Brief && Brief.trim() !== '') {
            setBriefError('');
        }

        if (CompanyWebSite) {
            const urlPattern = /^(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,6})+$/;

            if (urlPattern.test(CompanyWebSite)) {
                setCompanyWebSiteError('');
            }
        }
        if (selectedOption.employe !== undefined && selectedOption.employe !== 'Select an option...' && selectedOption.employe !== '') {
            setEmployeError('');
        }
        if (selectedIndustry.length > 0) {
            setIndustryError('');

        }
        if (selectedSolution.length > 0 || selectedFeaturedSolution.length > 0) {
            setSolutionError('');
        }
        if (selectedFounded !== undefined && selectedFounded !== 'Select an option...' && selectedFounded !== '') {
            setYearError('');
        }

        if (Object.keys(selectedGeographicData).length > 0) {
            setGeographicSalesError('');
        }
        if (selectedOption.companyHeadCarter !== undefined && selectedOption.companyHeadCarter !== 'Select an option...' && selectedOption.companyHeadCarter !== '') {
            setCompanyHeadCarterError(' ');
        }
    }, [selectedOption, selectedGeographicData, Brief]);

    return (
        <>
            <BuyerStep
                validatedSteps={props?.validateSteps}
                currentStep={props?.currentStep}
                handleContinue={goToNextStep}
                setCurrentStep={props?.setCurrentStep}
            />
            <div className='contentUser acquisition'>
                <div className='contentBorder row'>
                    <div className='form-group required mb-4 p-0'>
                        <label htmlFor='Brief'>Outline a brief overview of your company </label>
                        {
                            briefError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <textarea
                            id='Brief'
                            className='form-control mt-2'
                            maxLength={450}
                            value={Brief}
                            onChange={(e) => {
                                setBrief(e.target.value);
                                updateState('brief', e.target.value);
                            }}
                        />

                    </div>
                    <div className='form-group not-required mb-4 p-0'>
                        <label htmlFor=''>What is your company name? </label>
                        <input maxLength={100}
                            value={CompanyName}
                            onChange={(e) => {
                                setCompanyName(e.target.value);
                                updateState('companyname', e.target.value);
                            }} className='form-control mt-2' type="text" required name="" id="" />
                    </div>
                    <div className='form-group not-required mb-4 p-0'>
                        <label htmlFor=''>What is your website?</label>

                        <input maxLength={100}
                            value={CompanyWebSite}
                            onChange={(e) => {
                                setCompanyWebSite(e.target.value);
                                updateState('companywebsite', e.target.value);
                            }} className='form-control mt-2' type="text" required name="" id="" />
                        {
                            CompanyWebSiteError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> {CompanyWebSiteError} </span>{" "}
                                </div>
                            )
                        }
                    </div>
                    <div className='form-group required mb-4 p-0'>
                        <label htmlFor=''>Where is your company headquartered?</label>
                        {
                            companyHeadCarterError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> {companyHeadCarterError}</span>{" "}
                                </div>
                            )
                        }
                        <select className='form-control mt-2' value={selectedOption.companyHeadCarter} onChange={(e) => { handleSelectChange(e, 'companyHeadCarter'); updateState('companyHeadCarter', e.target.value); }}>
                            {renderOptions(companyHeadCarterData?.data)}
                        </select>

                    </div>
                    <div className='form-group required mb-4 p-0'>
                        <label htmlFor=''>How many people does your company employ? </label>
                        {
                            employeError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <select className='form-control mt-2' value={selectedOption.employe} onChange={(e) => { handleSelectChange(e, 'employe'); updateState('employe', e.target.value); }}>
                            {renderOptions(employeesData?.data)}
                        </select>

                    </div>
                    <div className='form-group required mb-4 p-0'>
                        <label htmlFor=''>In what year was your business founded? </label>
                        {
                            yearError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <select
                            className='form-control mt-2'
                            name='founded'
                            onChange={(e) => { handleFoundedChange(e); updateState('founded', e.target.value); }}
                            value={selectedFounded || ''}
                        >
                            <option value="">Select an option ...</option>
                            {
                                years?.map((el: any, index: any) => (
                                    <option key={index} value={el}>{el}</option>
                                ))
                            }
                        </select>

                    </div>
                    <div className="form-group required pb-4 px-0 selectSol">
                        <label className='mb-2' htmlFor=''>What vertical industry(ies) do you serve? </label>
                        {
                            industryError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <div className="d-flex mb-1 w-100 align-items-center">
                            <div className="btn btn-devinsider m-0" onClick={() => setShowPIndustry(true)}>{txtBtnIndustry}</div>
                            <div className="listOf more">
                                {' '}
                                <div className='topicAds'>
                                    {formState?.targeted_industry?.map((el: any, key: number) => (
                                        <span className='topic' key={key}>{el}</span>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="form-group required selectSol p-0">
                        <label className='mb-2' htmlFor=''>What horizontal solution(s) do you provide? </label>
                        {
                            solutionError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <div className="d-flex mb-1 w-100 mb-3 align-items-center">
                            <div className="btn btn-devinsider m-0" onClick={() => setShowPSolution(true)}>{txtBtnCat}</div>
                            <div className="listOf more">
                                {' '}
                                <div className='topicAds'>
                                    {[...formState?.software_solution, ...formState?.featured_solution]?.filter((value, index, self) => self.indexOf(value) === index)
                                        ?.map((el: any, key: number) => (
                                            <span className='topic' key={key}>{el}</span>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='form-group required mb-4 p-0'>
                        <label htmlFor=''>What is your geographic sales footprint? </label>
                        {
                            geographicSalesError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <span className="selectalloverstyle">Select all countries where you sell your solution</span>

                        <div className="geographicList">
                            <Accordion defaultActiveKey={activeId}>
                                <Countries
                                    geographicData={geographicData}
                                    activeId={activeId}
                                    toggleActive={toggleActive}
                                    data={formState.geographic_sales_footprint}
                                    stateName={"geographic_sales_footprint"}
                                    updateState={updateState}
                                    isEdit={false}
                                />
                            </Accordion>

                        </div>
                    </div>
                </div>
                <div className='text-align-right stepsButtons'>
                    <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                        Cancel
                    </button>
                    <button className='btn btn-devinsider save' onClick={() => goToNextStep(props?.currentStep + 1, true)}>
                        Finish
                    </button>
                </div>
            </div>
            
            <ModalIndustryList
                show={showPIndustry}
                cancel={backTarget}
                addIndustry={addTargetedIndustry}
                removeIndustry={removeTargetedIndustry}
                industrySelected={selectedIndustry}
                valid={validTargetedIndustry}
            />
            <ModalSolutionList
                show={showPSolution}
                cancel={backSolution}
                dataSolutions={horizontalsData?.data}
                addSoftwareSolution={addSoftwareSolution}
                solutionSelected={selectedSolution}
                valid={validSoftwareSolution}
                addFeaturedSolution={addFeaturedSolution}
                featuredSolutionSelected={selectedFeaturedSolution}
                removeItem={removeItemSelected}
                removeSolution={removeSolution}
            />
        </>
    );
}