let _ = require('lodash');

export const solutionsTextToDisplay = (softwareSolutions: Array<string>, featuredSolutions: Array<string>) => {
    const solutions = _.uniq([...softwareSolutions, ...featuredSolutions]);

    return solutions?.length > 0
            ? (solutions?.length + ' solution' + (solutions?.length > 1 ? 's' : '') + ' selected')
            : 'Select horizontal solution(s)';
};

export const industriesTextToDisplay = (industries: Array<string>) => {
    return industries?.length > 0
        ? (industries?.length + (industries?.length > 1 ? ' industries' : ' industry') + ' selected')
        : 'Select vertical industry(ies)';
};